@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "syne";
  src: url("../public/assets/fonts/Syne-Medium.ttf");
}

@font-face {
  font-family: urbanist;
  src: url("../public/assets/fonts/Urbanist-Regular.ttf");
}

@font-face {
  font-family: archivo;
  src: url("../public/assets/fonts/Archivo-Regular.ttf");
}

.preloader {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden !important;

  .texts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 280px;
    font-size: 20px;
    overflow: hidden;
    font-weight: 800;
    opacity: 0;
    color: white;

    @include media("<=phone") {
      font-size: 17px;
      width: 240px;
    }
  }
}

.float_up {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.item-main .item-video video {
  transform: scale(0.5);
  clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
}

.header {
  position: absolute;
  bottom: 35%;
  transform-origin: center center;
  width: 100%;
  display: flex;
}

.header-item {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  transform: scale(0.25);
}

.header-item-1 {
  left: 18vw;
}

.header-item-2 {
  right: 18vw;
}

.letter {
  flex: 1;
  font-size: 17vw;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

video {
  object-fit: cover;
}

.container {
  display: flex;
  position: relative;
}

.items {
  width: 100%;
  display: flex;
}

.item {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.navlink.active {
  color: #298bc1 !important;
}

.under {
  text-decoration: underline;
  text-decoration-thickness: 10px;
  text-underline-offset: -1px;
  text-decoration-color: #298bc1;
  text-decoration-skip-ink: none;
}

.parallax-bg {
  width: 110%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.stickyscroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Internet Explorer, Edge and Firefox */
.stickyscroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
